// 404.js
import Head from "next/head";
import Link from "next/link";
import Header from "@components/header";

const FourOhFour = () => { 

	return (
		<>
			<Head>
				<title>Not Found | Uncovered</title>
			</Head>
			<Header />
			<main className="bg-gray-100 pb-10">
				<section className="bg-black pb-80 pt-8 px-4 text-white md:pb-96 md:pt-24 md:text-center">
					<div className="max-w-6xl mx-auto px-4 xl:px-0">
						<div className="relative">
							<h1 className="text-2xl	md:text-6xl">Page Not Found</h1>
							<p className="max-w-3xl mt-6 mx-auto mb-10 md:text-xl hover:underline">
								<Link href="/cases">Explore Cold Cases</Link>
							</p>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};


export default FourOhFour;